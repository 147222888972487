import React from "react";
import styles from "./index.module.scss";

const About = () => {
  return (
    <section id="about" className={styles.about}>
      <h2>About</h2>
      <div className={styles.aboutInner}>
        <div className={styles.aboutLeft}>
          <p>
            Jujubee is the stage name of Airline Inthyrath, a professional
            singer, songwriter, drag performer, makeup artist, and comedian from
            Boston, Massachusetts. Style and beauty have always played a major
            role in Jujubee’s career. On top of working as a former professional
            makeup artist for MAC cosmetics, Jujubee is one of America’s most
            beloved fashion Drag Queens. Best known for being a contestant on
            Season 2 of “RuPaul’s Drag Race”, Season 1 of “RuPaul’s Drag Race
            All Stars”, “RuPaul’s Drag U.“, and a top 3 finalist on Season 5 of
            “RuPaul’s Drag Race All Stars”. Jujubee is also one of four hosts on
            the hit TLC makeover series “Dragnificent”. Jujubee has released 2
            EPs. “good juju : vol. 1” hit #7 on the iTunes Singer / Songwriter
            Albums Chart where “good juju: vol. 2" peaked at #1.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
