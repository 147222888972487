import React, { useEffect, useState, memo } from "react";
import ReactPlayer from "react-player";
import ModalVideo from "react-modal-video";
import { Link } from "gatsby";
import { get } from "lodash";
import useWindowSize from "@utilities/window-size.js";
import headerDesktop from "@images/jujubee-header-desktop.jpg";
import headerMobile from "@images/jujubee-header-mobile.jpg";

import styles from "./index.module.scss";

const HeaderVideo = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const windowSize = useWindowSize();

  return (
    <div className={`${styles.header}`}>
      <div className={`${styles.headerImageDesktop}`}>
        <img src={headerDesktop} />
      </div>
      <div className={`${styles.headerImageMobile}`}>
        <img src={headerMobile} />
      </div>
    </div>
  );
};

export default memo(HeaderVideo);
