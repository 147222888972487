import React from "react";
import styles from "./index.module.scss";
const ContactFooter = () => {
  return (
    <footer id="contact" className={styles.footer}>
      <div className="_92d742">
        <div>
          <h2>Contact</h2>
          <ul className={styles.footerList}>
            <li className={styles.footerListItem}>
              <h4>
                <span>Management</span>
              </h4>
              <div className="name">David Charpentier</div>
              <div className="email">
                <a href="mailto:David@ProducerEntertainment.com">
                  David@ProducerEntertainment.com
                </a>
              </div>
            </li>
            <li className={styles.footerListItem}>
              <h4>
                <span>Theatrical/TV/Film</span>
              </h4>
              <div className="name">Jacob Slane</div>
              <div className="email">
                <a href="mailto:Jacob@ProducerEntertainment.com">
                  Jacob@ProducerEntertainment.com
                </a>
              </div>
            </li>
            <li className={styles.footerListItem}>
              <h4>
                <span>Booking Agent</span>
              </h4>
              <div className="name">Michael Benedetti</div>
              <div className="email">
                <a href="mailto:Michael@ProducerEntertainment.com">
                  Michael@ProducerEntertainment.com
                </a>
              </div>
            </li>
            <li className={styles.footerListItem}>
              <h4>
                <span>Music</span>
              </h4>
              <div className="name">Ryan Aceto</div>
              <div className="email">
                <a href="mailto:Ryan@ProducerEntertainment.com">
                  Ryan@ProducerEntertainment.com
                </a>
              </div>
            </li>
            <li className={styles.footerListItem}>
              <h4>
                <span>Store Questions</span>
              </h4>
              <div className="name">Web Store Help</div>
              <div className="email">
                <a href="mailto:Store@ProducerEntertainment.com">
                  Store@ProducerEntertainment.com
                </a>
              </div>
            </li>
            <li className={styles.footerListItem}>
              <h4>
                <span>Press/Media</span>
              </h4>
              <a href="https://www.producerentertainment.com">
                <div className="name"></div>
              </a>
              <div className="email">
                <a href="mailto:Press@ProducerEntertainment.com">
                  Press@ProducerEntertainment.com
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default ContactFooter;
