import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { get } from "lodash";
import HeaderVideo from "@components/header-video";
import SEO from "@components/seo";
import Layout from "@components/layout.js";
import Music from "@components/music";
import About from "@components/about";
import ContactFooter from "@components/contact-footer";
import Header from "@components/header/index.js";
import Video from "@components/video";
import ContestModal from "@components/contest-modal";
// import Music from "@components/music";
import ProductsGrid from "@components/products-grid";

const IndexPage = ({ data }) => {
  const collections = get(data, "products.edges", []);
  const { node: homepageProducts } = collections.filter(
    ({ node: { title } }) => {
      return title === "Home page";
    }
  )[0];
  // return <div />;
  return (
    <Layout>
      <SEO title="Home" />
      <HeaderVideo />
      <Header />
      <Music />
      <ProductsGrid
        title="Shop"
        limit={null}
        products={homepageProducts.products}
      />
      <About />
      <ContactFooter />
    </Layout>
  );
};

export const showQuery = graphql`
  query homeQuery {
    products: allShopifyCollection {
      edges {
        node {
          title
          products {
            id
            title
            handle
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            variants {
              id
              title
              price
              availableForSale
              shopifyId
            }
            images {
              originalSrc
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
