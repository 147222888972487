import React from "react";
import SpotifyIcon from "@images/spotify-brands.inline.svg";
import AppleIcon from "@images/apple-brands.inline.svg";
import albumArtV1 from "@images/goodjuju_v1.jpg";
import albumArtV2 from "@images/goodjuju_v2.jpg";
import styles from "./index.module.scss";

const Music = () => {
  return (
    <section id="music" className={styles.music}>
      <div className={styles.musicItem}>
        <h2>Good Juju v1</h2>
        <div className={styles.musicInner}>
          <a target="_blank" href="https://lnk.to/gjv1">
            <img
              draggable={false}
              title="Good Juju v1"
              alt="Good Juju v1"
              src={albumArtV1}
            />
          </a>
          <br />
          <a target="_blank" href="https://lnk.to/gjv1">
            Stream V1 HERE
          </a>
        </div>
      </div>
      <div className={styles.musicItem}>
        <h2>Good Juju v2</h2>
        <div className={styles.musicInner}>
          <a target="_blank" href="https://lnk.to/goodjujuv2">
            <img
              draggable={false}
              title="Good Juju v2"
              alt="Good Juju b2"
              src={albumArtV2}
            />
          </a>
          <br />
          <a target="_blank" href="https://lnk.to/goodjujuv2">
            Stream V2 HERE
          </a>
        </div>
      </div>
    </section>
  );
};

export default Music;
